<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                System config
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-3 mb-1">
            <label
              for="name"
              class="form-label"
            >Max size upload files</label>
            <input
              v-model="item.max_post_size"
              type="number"
              class="form-control"
              name="name"
            >
          </div>
          <div class="col-3 mb-1">
            <label
              for="name"
              class="form-label"
            >Max number of pdf pages</label>
            <input
              v-model="item.max_pdf_pages"
              type="number"
              class="form-control"
              name="name"
            >
          </div>
        </div>
        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      item: 'systemConfig/items',
    }),
  },
  async mounted() {
    await this.$store.dispatch('systemConfig/filter')
  },
  methods: {
    async save() {
      this.sending = true

      try {
        await this.$store.dispatch('systemConfig/update', this.item)
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      Vue.swal('The system config has been saved correctly!', '', 'success')

      this.sending = false
    },
  },
}
</script>
